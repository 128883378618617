export default {
  generalActions: {
    "Cancel": "Batal",
    "OK": "Oke",
    "Back": "Kembali",
    "Log in": "Masuk",
    "Log out": "Keluar",
    "Yes": "Ya",
    "No": "Tidak",
    "Exit": "Keluar",
    "Enter": "Masuk",
    "Send": "Kirim",
    "Next": "Berikutnya",
    "Copy Link": "Salin link",
    "Submit": "Kirim laporan"
  },
  generalTexts: {
    "Loading": "Sebentar ya...",
    "Date": "Tanggal",
    "Username": "Nama pengguna",
    "Live Trivia": "Live Trivia",
    "views": "Dilihat",
    "comment": "Komentar",
    "comments": "Komentar",
    "likes": "Like",
    "No Stream at the moment": "Tidak ada tayangan saat ini",
    "Stream Ended": "Tayangan berakhir",
    "Host": "Host",
    "Viewers": "Penonton",
    "Viewer": "Penonton",
    "There is a problem": "Ada gangguan nih",
    "Please try again later": "Coba lagi nanti ya.",
    "Your account is logged in on another device": "Akun kamu sedang login di perangkat lain",
    "This account has been banned": "Akun ini telah diblok",
    "Oops__Seems like your network has problem__ Please try again": "Ada gangguan koneksi. Cek dulu dan coba lagi.",
    "This will allow you to communicate to other users": "Kamu akan bisa berkomunikasi dengan pengguna lainnya.",
    "You have been muted by Admin": "Kamu dibisukan oleh Admin",
    "You are kicked out by Host": "Kamu dikeluarkan oleh pembuat tayangan",
    "User have been kicked out stream": "Akun telah dikeluarkan dari tayangan",
    "Tap to unmute": "Klik untuk menyalakan suara",
    "Please send you message later": "Pesannya lagi penuh, silakan kirim lagi nanti ya.",
    "Your report was submitted": "Laporan kamu sudah terkirim!",
    "You have already reported": "Kamu sudah melapor",
    "Success": "Terima kasih"
  },
  liveTexts: {
    "Share this livestream!": "Bagikan tayangan ini",
    "Say something": "Katakan sesuatu...",
    "Share": "Bagikan",
    "Resend": "Kirim ulang",
    "Done": "Selesai",
    "Wait": "Tunggu",
    "Leave Stream": "Tinggalkan tayangan",
    "Live": "Siaran langsung",
    "has joined the livestream": "sudah bergabung ke penayangan ini",
    "joined the livestream": "ikut bergabung",
    "liked the livestream": "menyukai tayangan",
    "and ___ other viewer": "dan {0} penonton lain",
    "and ___ other viewers": "dan {0} penonton lain",
    "Pin message": "Tandai pesan",
    "Unpin message": "Batal tandai pesan",
    "Sorry, your comment needs to be appropriate and should not contain any URL Please try again": "Maaf, komen harus menggunakan kata-kata yang pantas dan tidak mengandung link. Silakan coba lagi.",
    "Sorry, its too late to join the game but you can definitely watch the fun!": "Maaf, kamu terlambat untuk ikutan. Tapi masih bisa nonton keseruannya lho!"
  },
  triviaTexts: {
    "How to play?": "Cara main",
    "Get ready to play": "Siap-siap...",
    "Lets go!": "Ayo main!",
    "Leaderboard": "Papan skor",
    "How to": "Cara",
    "Instructions": "Petunjuk",
    "Revive": "Nyawa Tambahan",
    "Lives": "Nyawa",
    "Life": "Nyawa",
    "Winner list in ____(time)": "Pengumuman pemenang dalam {0}",
    "Got it!": "Oke, mengerti",
    "Remaining": "Tersisa",
    "Times up": "Waktu habis",
    "Option": "Pilih jawaban",
    "Correct": "Benar",
    "Wrong": "Salah",
    "Thats right!": "Benar sekali!",
    "Answer": "Jawaban",
    "Answers": "Jawaban",
    "View results": "Lihat hasil",
    "Disqualified": "Diskualifikasi",
    "Live Trivia has already started!": "Pemainan sudah dimulai!",
    "Sorry, this is ongoing and you cant join midway": "Maaf, kamu gak bisa bergabung di tengah permainan",
    "Youre out of the game!": "Kamu keluar dari permainan",
    "Aw, Okay": "Ah, oke",
    "Get Revives": "Dapatkan Nyawa Tambahan",
    "Revive Left": "Revive left",
    "Revive(s) Left": "Revive(s) left",
    "Youre out of Revive": "Kamu keluar dari permainan",
    "Congratulations!": "Selamat!",
    "Top ___": "Top {0}",
    "Time remaining": "Waktu tersisa",
    "Countdown": "Hitungan mundur",
    "Question": "Pertanyaan",
    "Game rules: Score the most correct answers to win_ Once you get a question wrong, youll be disqualified_ However, if you have Revives, you can use it to stay in the game!": "Cara main: Jawab dengan benar sebanyak-banyaknya dan raih skor tertinggi untuk menang. Sekali salah jawab, kamu akan keluar dari permainan. Tapi, jika kamu punya Nyawa Tambahan, kamu bisa pakai untuk lanjut main!",
    "You have ___ Revive(s) left": "Kamu masih punya {0} Nyawa Tambahan.",
    "You have ___ Revive left": "Kamu masih punya {0} Nyawa Tambahan.",
    "Use Revive": "Gunakan Nyawa Tambahan",
    "Use": "Gunakan",
    "Forfeit game": "Menyerah",
    "Oh no, you got that wrong!": "Sayang sekali, jawaban kamu salah!",
    "No, its okay": "Gak apa-apa.",
    "Yes, use!": "Ya, gunakan!",
    "Question in ____(time)": "Pertanyaan dalam {0}",
    "Answer in ____(time)": "Jawab dalam {0}",
    "Start Trivia in ____(time)": "Mulai permainan dalam {0}",
    "Congratulations! You won! You get:": "Selamat! Kamu menang! <br />Kamu dapat:",
    "___ credits": "Credits Rp{0}",
    "Congratulations! You won! You get: ____ credits": "Selamat! Kamu menang! <br />Kamu dapat: Credits Rp{0}",
    "Congratulations! Each winner gets:": "Selamat! <br />Tiap pemenang dapat:",
    "Congratulations! Each winner gets: ____ credits": "Selamat! <br />Tiap pemenang dapat: Credits Rp{0}",
    "Aww, no winner today_ Better luck next time!": "Yah, gak ada yang menang hari ini. <br />Semoga lain kali beruntung ya!",
    "No one gets": "gak berhasil direbut siapa pun",
    "winner": "pemenang",
    "winners": "pemenang",
    "Do not join late or you can only watch the fun": "Jangan telat. Kalo telat kamu cuma bisa nonton aja.",
    "Tap on the options to submit your answer under 10 seconds!": "Klik jawaban kamu dalam 10 detik!",
    "One wrong answer and youll be out_ However, a Revive can be used once per game to save you!": "1 jawaban salah dan kamu akan keluar dari permainan. Tapi kamu bisa menggunakan Nyawa Tambahan 1x.",
    "Survive till the end to win!": "Bertahanlah hingga selesai untuk menang!",
    "The total prize pool will be split equally by all winners who will receive credits, yay!": "Total hadiah akan dibagi rata ke seluruh pemenang!",
    "Each winner gets _ credits!": "Tiap pemenang dapat Credits Rp{0}!"
  },
  reportTexts: {
    "Reason for reporting": "Alasan melapor",
    "Sexual content": "Konten mengandung unsur seksual",
    "Violent or repulsive content": "Konten tidak pantas atau mengandung kekerasan",
    "Hateful or abusive content": "Konten mengandung kebencian atau pelecehan",
    "Harmful dangerous acts": "Menampilkan aksi berbahaya",
    "Child abuse": "Pelecehan anak",
    "Spam or misleading content": "Konten spam atau menyesatkan",
    "Infringes my rights": "Konten melanggar hak cipta, hak asasi, atau hak lainnya"
  }
};